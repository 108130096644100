(function ($) {
	/*---------------------------------------
	|	INITIALIZE							|
	*---------------------------------------*/
	$(document).ready(function($) {
		//Set the venue image
		var newImage = $('.homepage-spaces li.active').attr('data-img');
		var alt = $('.homepage-spaces .space-container li.active span.title').text();
		$('#active-space-image img').attr('src', newImage);
		$('#active-space-image img').attr('alt', alt);

		// Set the link inside More about button on venue image
		var imgMoreLinkText = $('.homepage-spaces .venue-title.active').text();
		$('#active-space-image .more-button').text('More about ' + imgMoreLinkText);
		var imgMoreLinkUrl = $('#space-description-container .spaces-description.active a').attr('href');
		$('#active-space-image .more-button').attr('href', imgMoreLinkUrl);

		//Set Gallery Sizes
		gallerySetup();
	});

	/*---------------------------------------
	|	BINDINGS							|
	*---------------------------------------*/
	$(document).ready(function($) {
		//Venue Swap
		$('.homepage-spaces li').click(function() {
			venueSwap(this);
		});

		//Gallery slider
		$('.gallery-control').click(function() {
			gallerySlider($(this).attr('data-direction'));
		});

		$(window).resize(gallerySetup);

		//GALLERY STUFF
		$('.gallery-image').click(function() {
			var source = $(this).attr('data-image');
			galleryModalShow(source);
		});

		$('#homepage-gallery-modal').click(galleryModalHide);
		//GALLERY STUFF

		//Make the mobile persona menu dropdown take only 1 tap instead of two
		$('#mobile-persona-dropdown a').on('click touchend', function(e) {
			var el = $(this);
			var link = el.attr('href');
			window.location = link;
		});
	});

	/*---------------------------------------
	|	QUICK LINKS							|
	*---------------------------------------*/
	$('#mobile-persona-dropdown').click(quickLinks);
	function quickLinks() {
		$('#mobile-persona-dropdown-holder').toggleClass('active');
	}

	/*---------------------------------------
	|	VENUES								|
	*---------------------------------------*/
	function venueSwap(target) {
		//Switch the 'active' tab to the proper one
		$('.homepage-spaces li').removeClass('active');
		$(target).addClass('active');

		//Nab the image from that data attr and then stick it in the top img
		var newImage = $('.homepage-spaces li.active').attr('data-img');
		$('#active-space-image img').attr('src', newImage);

		//Now figure out which description should display and display it
		var count = $('.homepage-spaces li.active').attr('data-count');
		$('.spaces-description').removeClass('active');
		$('.spaces-description').eq(count).addClass('active');
		// Display corresponding title
		$('.venue-title').removeClass('active');
		$('.venue-title').eq(count).addClass('active');
		// Display corresponding title and url in more button
		var imgMoreLinkText = $('.homepage-spaces .venue-title.active').text();
		$('#active-space-image .more-button').text('More about ' + imgMoreLinkText);
		var imgMoreLinkUrl = $('#space-description-container .spaces-description.active a').attr('href');
		$('#active-space-image .more-button').attr('href', imgMoreLinkUrl);
	}

	/*---------------------------------------
    |	GALLERY								|
    *---------------------------------------*/
	function gallerySetup() {
		var totalWidth = $('#homepage-gallery-treadmill').width();
		if($(window).width() < 590 ) {
			$('.gallery-image').width(totalWidth / 2);
		}
		else if ($(window).width() < 992) {
			$('.gallery-image').width(totalWidth / 4);
		}
		else {
			$('.gallery-image').width(totalWidth / 6);
		}

	}

	function gallerySlider(direction) {
		if(direction === 'left') {
			$('.gallery-image:first-child').css('width', '0px');
			setTimeout(function() {
				$('#homepage-gallery-treadmill').append($('.gallery-image:first-child'));
			}, 200);
			setTimeout(function() {
				gallerySetup();
			}, 250);
		}
		else {
			$('.gallery-image:last-child').css('width', '0px');
			$('#homepage-gallery-treadmill').prepend($('.gallery-image:last-child'));

			setTimeout(function() {
				gallerySetup();
			}, 200);
		}
	}

	function galleryModalShow(source) {
		$('#homepage-gallery-modal img').attr('src', source);
		$('#homepage-gallery-modal').addClass('active');
		var margin_top = (($(window).height() - $('#homepage-gallery-modal img').height()) / 2);
		$('#homepage-gallery-modal img').css('margin-top', margin_top);
	}

	function galleryModalHide() {
		$('#homepage-gallery-modal').removeClass('active');
	}

	// function to detect page type to adjust the height of the white block on list pages
	function setBlockHeight()
	{
		// events list page
		if($("#tribe-events-content").length !== 0)
			getBlockMaxHeight("#tribe-events-content", ".tribe-events-loop .single-event-post");
		// venues list page
		else if($("#display-venue-results").length !== 0)
			getBlockMaxHeight("#display-venue-results .venue-list", ".venue-item");
		// vendors list page
		else if($("#display-vendors-posts-results").length !== 0)
			getBlockMaxHeight("#display-vendors-posts-results .postbox.list-unstyled", ".postbox_content.vendor-post");
		// hotels list page
		else if($("#display-hotels-posts-results").length !== 0)
			getBlockMaxHeight("#display-hotels-posts-results", ".hotel-list-item");
		// news list page
		else if($("#display-news-posts-results").length !== 0)
			getBlockMaxHeight("#display-news-posts-results", ".postbox_content.news-post");
		// contact us list page
		else if($("#team-list").length !== 0)
			getBlockMaxHeight("#team-list", ".team-member .postbox_content");
		// general blocks template
		else if($("#block-list").length !== 0)
			getBlockMaxHeight("#block-list", ".block-item .postbox_content");
	}

	// function to adjust the white blocks height in list pages. Max height of the block will be assigned to all.
	function getBlockMaxHeight(parentSelector, blockSelector){
		// set height to auto - so that any previously set height is removed
		$(parentSelector + " " + blockSelector).css("height", "auto");
		// variable to store the max height
		var maxHeight = 0;
		// calc the max height
		$(parentSelector + " " + blockSelector).each(function(){
			var thisHeight = $(this).outerHeight();
			if(thisHeight > maxHeight){maxHeight = thisHeight;}
		});
		// set the max height for desktops only because tablet and mobile devices will have the blockes stacked.
		if($(window).width() >= 992)
			$(parentSelector + " " + blockSelector).css("height", maxHeight + "px");
	}

	// adjust the white block heights
	$(document).ready(function($) {
		if($(window).width() >= 992)
			setBlockHeight();
		$(window).resize(function()
		{
			setBlockHeight();
		});
	});
})(jQuery);
