/**
 * Show/hide "Show on Main Gallery" field on gallery edit page
 */
(function ($) {
    const checkbox = $('div').find('[data-name="show_on_main_gallery"]');
    const weddingCheckbox = $('#taxonomy-image-event-type input[value="47"]');
    checkbox.hide();
    $(document).ready( function(){
        if (weddingCheckbox) {
            if ($('.post-type-image') && weddingCheckbox[0] && (weddingCheckbox[0].checked || weddingCheckbox[1].checked)) {
                checkbox.show();
            } else {
                checkbox.hide();
            }
            weddingCheckbox.change(function() {
                if ($('.post-type-image') && weddingCheckbox[0] && (weddingCheckbox[0].checked || weddingCheckbox[1].checked)) {
                    checkbox.show();
                } else {
                    checkbox.hide();
                }
            })
        }
    });
})(jQuery);

