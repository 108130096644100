/***********
/
/  Bindings
/
************/
jQuery(document).ready(function() {
  //limit to one category and tag selection
  jQuery('.news-cat-check').click(function(){
    if( jQuery(this).is(":checked") ){
      var cat_checked = true;
    }

    jQuery('.news-cat-check:checkbox').removeAttr('checked');

    if( cat_checked ){
      jQuery(this).attr('checked', 'checked');
    }
  });

  jQuery('.news-tag-check').click(function(){
    if( jQuery(this).is(":checked") ){
      var tag_checked = true;
    }

    jQuery('.news-tag-check:checkbox').removeAttr('checked');

    if( tag_checked ){
      jQuery(this).attr('checked', 'checked');
    }
  });

  //click category filtering
  jQuery('.category-select').click(function(){
    show_or_hide_category_filters();
  });

  //click tag filtering
  jQuery('.tag-select').click(function(){
    show_or_hide_tag_filters();
  });

  //view filter results
  jQuery('#view-news-results').click(function(){
    show_or_hide_news();
  });

  //reset filters
  jQuery('#reset-filters').click(function(){
    reset_filters();
  });
});

/************
/
/  Functions
/
*************/
function show_or_hide_category_filters() {
  if( jQuery('#category-select').hasClass('filter-closed') ){
    jQuery('.news-categories').show();
    jQuery('#category-select').removeClass('filter-closed');
    jQuery('.news-tags').hide();
    jQuery('#tag-select').addClass('filter-closed');
  }
  else{
    jQuery('.news-categories').hide();
    jQuery('#category-select').addClass('filter-closed');
  }
}

function show_or_hide_tag_filters() {
  if( jQuery('#tag-select').hasClass('filter-closed') ){
    jQuery('.news-tags').show();
    jQuery('#tag-select').removeClass('filter-closed');
    jQuery('.news-categories').hide();
    jQuery('#category-select').addClass('filter-closed');
  }
  else{
    jQuery('.news-tags').hide();
    jQuery('#tag-select').addClass('filter-closed');
  }
}

function show_or_hide_news() {
  jQuery('.news-categories').hide();
  jQuery('.news-tags').hide();
  jQuery('#category-select').addClass('filter-closed');
  jQuery('#tag-select').addClass('filter-closed');
  jQuery('.news-item').show();

  var checkedValues = jQuery('input:checkbox:checked').map(function() {
    return this.value;
  }).get();

  if(checkedValues.length > 0){
    var cats = checkedValues.toString().split(",");
    var i = 0;
    for(i; i < cats.length; i++){
      jQuery('.news-item:not(.'+ cats[i] + ')').hide();
    }
  }
}

function reset_filters() {
  jQuery('input:checkbox').removeAttr('checked');
  jQuery('.news-item').show();
}

