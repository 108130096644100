var $j = jQuery.noConflict();

$j(document).ready(function(){
	//$j('.thumbclick').css({backgroundSize: "cover"});

	/**
	 * Mobile Menu Stuff
	 */


		// We are using sidr to handle our menu JS
		// https://www.berriart.com/sidr/

	var sidrName = "sidr-main";

	jQuery("#mobile-menu-toggle").sidr({
		name: sidrName,
		side: "left",
		source: ".navbar-nav",
		renaming: false,
		onOpen: function() {
			jQuery(window).on("click.sidr", function(e) {
				jQuery.sidr("close", sidrName);
			});
			jQuery("#mobile-menu-toggle").addClass("menu-open");
			// jQuery('#mobile-menu-toggle').attr('aria-expanded', true);
			// jQuery('#mobile-menu-toggle .menu-label').text('Close');
			jQuery('.site-content').prepend('<div class="mobile-menu-overlay"></div>');
			// jQuery('.page').removeAttr("style");
			// jQuery('.site-header').addClass('sidr-open');

		},
		onClose: function() {
			jQuery(window).off("click.sidr");
			jQuery("#mobile-menu-toggle").removeClass("menu-open");
			// jQuery('#mobile-menu-toggle').attr('aria-expanded', false);
			// jQuery('#mobile-menu-toggle .menu-label').text('Menu');
			jQuery('.mobile-menu-overlay').remove();
			// jQuery('.site-header').removeClass('sidr-open');
		}
	});

	// Don't you dare close me out!
	jQuery("#" + sidrName).on("click.sidr", function(e) {
		e.stopPropagation();
	});

	jQuery(".sidr .menu-item-has-children > a").append('<i class="fa fa-sort-down" style="margin-left: 5px;position: absolute;top: 21px;right: 5px;"></i>');

	//Submenus
	jQuery(".sidr .menu-item-has-children > a > i").on("click", function(e) {
		e.preventDefault();
		var parent = jQuery(this).parent().parent();

		if (parent.children(".sub-menu").length > 0) {
			parent.children(".sub-menu").slideToggle();
		}
		return false;
	});

	$j("#kcsearch").click(function(){
		$j(this).attr("value", "");
	})

  //fancybox
  $j(".fancybox").fancybox();

  //Try to make the sidebar persona accordion menus take only 1 tap instead of two
  jQuery('#sidebar-persona-menu a').on('click touchend', function(e) {});


	// view all
	jQuery(".category-wrapper .category-title .view-all").click( function(){
		if (jQuery(this).parents(".category-wrapper").hasClass('all')) {
			jQuery(jQuery(this)).text('+');
			jQuery(jQuery(this).parents(".category-wrapper")).removeClass('all');
		} else {
			jQuery(jQuery(this)).text('\u2013');
			jQuery(jQuery(this).parents(".category-wrapper")).addClass('all');
		}
	})

	//Toggle accordion icons on open
	function togglePlus(e) {
	    jQuery(e.target)
	        .prev('.panel-heading')
	        .find("i.accordion-toggle")
	        .toggleClass('glyphicon-plus glyphicon-minus');
	}
	jQuery('#tribe-events .panel-heading').click(function(){
		jQuery(this).find("i.accordion-toggle").toggleClass('glyphicon-plus glyphicon-minus');
	});
	jQuery('#venueAccordion').on('hidden.bs.collapse', togglePlus);
	jQuery('#venueAccordion').on('shown.bs.collapse', togglePlus);

	jQuery('#venueTableAccordion').on('hidden.bs.collapse', togglePlus);
	jQuery('#venueTableAccordion').on('shown.bs.collapse', togglePlus);

	jQuery('#faqAccordion').on('hidden.bs.collapse', togglePlus);
	jQuery('#faqAccordion').on('shown.bs.collapse', togglePlus);


	//tribe reset search and filter fields
	$j("#tribe_events_filters_reset").on("click", function(event){
        $j("#tribe-bar-search").val("");
        $j("#tribe_events_filter_item_eventcategory h3").text("Category");
        $j("#tribe_events_filter_item_tags h3").text("Tags");
        $j("#tribe_events_filter_item_venues h3").text("Venues");
        $j("#tribe-bar-dates input").val('');
    });

	//vendor search field
	$j('.vendor-search-input input').focus(function(){
    if ( $j( this ).val () === $j( this ).attr ( 'placeholder' ) ) {
      $j(this).val('');
    }
	}).blur(function(){
	    if($j(this).val() == "")
	    {
	        $j(this).val($j(this).attr('placeholder'))
	    }
	});

	$j('input, textarea').placeholder();

	//select actives


	// $j('.selectborder').on('click', function(e){
 //    $j(".selectactive").removeClass('selectactive');
 //    $j(this).toggleClass('selectactive');
 //  });

	//events page change the filter values
	function updateFilterTitleText(eachCheckedInputSelector, defaultFilterTitle, filterTitleSelector, addTruncateSelector){
	  	var changeFilterText = "";
	    $j(eachCheckedInputSelector).each(function(index) {
	      if(index !== 0)
	        changeFilterText = changeFilterText + ", ";
	      changeFilterText = changeFilterText + $j(this).next("span").text();
	    })

	    if(changeFilterText === ""){changeFilterText = defaultFilterTitle;}
	    $j(filterTitleSelector).text(changeFilterText);
	    $j(addTruncateSelector).addClass("truncate").css("width", "90%");
	};

	$j("#tribe_events_filter_item_eventcategory .tribe-events-filter-checkboxes ul li").click(function(){
		updateFilterTitleText("#tribe_events_filter_item_eventcategory .tribe-events-filter-checkboxes ul li input:checked", "Event Category", "#tribe_events_filter_item_eventcategory h3 span, #tribe_events_filter_item_eventcategory h3",
			"#tribe_events_filter_item_eventcategory h3");
	});
	$j("#tribe_events_filter_item_tags .tribe-events-filter-checkboxes ul li").click(function(){
		updateFilterTitleText("#tribe_events_filter_item_tags .tribe-events-filter-checkboxes ul li input:checked", "Tag", "#tribe_events_filter_item_tags h3 span, #tribe_events_filter_item_tags h3",
			"#tribe_events_filter_item_tags h3");
	});
	$j("#tribe_events_filter_item_venues .tribe-events-filter-checkboxes ul li").click(function(){
		updateFilterTitleText("#tribe_events_filter_item_venues .tribe-events-filter-checkboxes ul li input:checked", "Tag", "#tribe_events_filter_item_venues h3 span, #tribe_events_filter_item_venues h3",
			"#tribe_events_filter_item_venues h3");
	});
	// parking widget
	$j(".widget-area aside").has("#LAZ_Widget").addClass('parking-widget');
});

