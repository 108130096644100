jQuery( document ).ready( function( $ ) {
    /**********************************************************************
     /
     /         BINDINGS
     /
     **********************************************************************/
    //click event types filtering
    jQuery('#service-type-select').click(function(){
        show_or_hide_type_filters();
    });

    jQuery('#service-status-select').click(function(){
        show_or_hide_status_filters();
    });

    //close filters when clicked outside
    jQuery("body").click(function(event){
        // close venue filtering if clicked anywhere outside of category filtering dropdown
        if(!jQuery(event.target).hasClass("service-status-select") &&
            !jQuery(".service-status-select").hasClass("filter-closed") &&
            !jQuery(event.target).parents("div").hasClass("service-statuses")&&
            !jQuery(event.target).hasClass("filter-title") &&
            !jQuery(event.target).hasClass("event-type-arrow")
        )
        {
            show_or_hide_status_filters();
        }

        // close event filtering if clicked anywhere outside of category filtering dropdown
        if(!jQuery(event.target).hasClass("service-type-select") &&
            !jQuery(".service-type-select").hasClass("filter-closed") &&
            !jQuery(event.target).parents("div").hasClass("service-types")&&
            !jQuery(event.target).hasClass("filter-title") &&
            !jQuery(event.target).hasClass("event-type-arrow")
        )
        {
            show_or_hide_type_filters();
        }
    });

    //limit to one status selection
    jQuery('.service-status-check').next('label').click(function(){

        if( $(this).prev().is(":checked") ){
            $(this).prev().removeAttr('checked');
            $(this).removeClass('selected-filter');

        }else
            $(this).prev().attr('checked', 'checked');

        if( $(this).prev().is(":checked") ){
            var status_checked = true;
        }

        $('.service-status-check:checkbox').removeAttr('checked');
        $('.service-status-check').next('label').removeClass('selected-filter');

        if( status_checked ){
            $(this).prev().attr('checked', 'checked');
            $(this).addClass('selected-filter');
        }
    });

    //reset filters
    jQuery('#reset-filters').click(function(){
        reset_filters();
    });


    // filter dropdown input selected
    jQuery(".service-statuses-list li").click(function(){
        // update the dropdown text
        updateServiceStatusFilterTitleText();
        // close the dropdown
        show_or_hide_status_filters();
    });

    jQuery(".service-types-list li").click(function(){
        // update the dropdown text
        updateServiceTypeFilterTitleText();
    });

    /**********************************************************************
     /
     /         AJAX FILTERING
     /
     **********************************************************************/
    $( '#view-vendor-results' ).click( function( event ) {
        jQuery( ".spinner" ).show();
        jQuery('.service-types').hide();
        jQuery('#service-type-select').addClass('filter-closed');
        jQuery('.service-statuses').hide();
        jQuery('#service-status-select').addClass('filter-closed');

        var service_types = $('.service-type-check:checkbox:checked').map(function () {
            return this.value;
        }).get();

        service_types = service_types.toString();

        var status = $('.service-status-check:checkbox:checked').map(function () {
            return this.value;
        }).get();

        status = status.toString();

        var service_type_total = $('.service-type-check:checked').size();
        var counter = 1;
        var typename = '';

        $('.service-type-check:checkbox:checked').each( function() {

            if(counter != service_type_total || service_type_total == 1){
                typename += '"' + $(this).data('typename') + '"';

                if(service_type_total > 1 && (counter + 1) != service_type_total){
                    typename += ', ';
                }
            }

            if(counter == service_type_total && service_type_total > 1){
                typename += ' and "' + $(this).data('typename') + '"';
            }

            counter++;

        });

        //statusname = statusname.toString();


        console.log(typename);

        $.ajax({
            type: 'GET',
            url: dsp.ajaxurl,
            data: {
                action: 'display_vendors_filter',
                types: service_types,
                statuses: status,
                typenames: typename,
                vendor_search: $('#vendor_search').val()
            },
        }).done( function( msg ) {
            $('.spinner').hide();
            $('#display-vendors-posts-results').html( msg );
        });
    });

});

/**********************************************************************
 /
 /         FUNCTIONS
 /
 **********************************************************************/

function show_or_hide_type_filters() {
    if( jQuery('#service-type-select').hasClass('filter-closed') ){
        jQuery('.service-types').show();
        jQuery('#service-type-select').removeClass('filter-closed');
        jQuery('.service-statuses').hide();
        jQuery('#service-status-select').addClass('filter-closed');
    }
    else{
        jQuery('.service-types').hide();
        jQuery('#service-type-select').addClass('filter-closed');
    }
}

function show_or_hide_status_filters() {
    if( jQuery('#service-status-select').hasClass('filter-closed') ){
        jQuery('.service-statuses').show();
        jQuery('#service-status-select').removeClass('filter-closed');
        jQuery('.service-types').hide();
        jQuery('#service-type-select').addClass('filter-closed');
    }
    else{
        jQuery('.service-statuses').hide();
        jQuery('#service-status-select').addClass('filter-closed');
    }
}

function updateServiceStatusFilterTitleText(){
    var changeFilterText = "";
    jQuery(".service-statuses-list li input:checked").each(function(index) {
        if(index !== 0)
            changeFilterText = changeFilterText + ", ";
        changeFilterText = changeFilterText + jQuery(this).next("label").text();
    })

    if (changeFilterText === "") {changeFilterText = "Service Type Status"};
    jQuery("#service-status-select .filter-title").text(changeFilterText);
};

function updateServiceTypeFilterTitleText(){
    var changeFilterText = "";
    jQuery(".service-types-list li input:checked").each(function(index) {
        if(index !== 0)
            changeFilterText = changeFilterText + ", ";
        changeFilterText = changeFilterText + jQuery(this).next("label").text();
    })

    if (changeFilterText === "") {changeFilterText = "Sevice Type Status"};
    jQuery("#service-type-select .filter-title").text(changeFilterText);
};

function reset_filters() {
    jQuery( ".spinner" ).show();
    jQuery('input:checkbox').removeAttr('checked');
    jQuery('.service-status-check').next('label').removeClass('selected-filter');
    jQuery.ajax({
        type: 'GET',
        url: dsp.ajaxurl,
        data: {
            action: 'display_vendors_filter',
            types: 'all',
            statuses: 'all'
        },
    }).done( function( msg ) {
        jQuery('.spinner').hide();
        jQuery('#display-vendors-posts-results').html( msg );
    });

    jQuery("#service-status-select .filter-title").text("Service Type Status");
    jQuery("#service-type-select .filter-title").text("Service Type");
}