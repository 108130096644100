jQuery(document).ready(function() {

	//Run the sizing function once to ensure everything is the right size.
	//check for IE8 or less.
	// if(jQuery.browser.msie && parseFloat(jQuery.browser.version) <=8)
	// {
	// 	// adding delay for ie8 and less so that the page is set up first before the gallery is resized.
	// 	setTimeout( function(){spaces_images_sizing();},3000 );
	// }
	// else
	// {
	// 	setTimeout( function(){spaces_images_sizing();}, 5 );
	// }

	//Run the swap function on the first image to force centering.
	//spaces_swap_image(jQuery('.spaces-hero-controls img').eq(0));

	//Bind swap function To other images
	// jQuery('.spaces-hero-controls img').click(function() {
	// 	spaces_swap_image(jQuery(this));
	// });
	//Old collapse, switched to Bootstrap Default to match functionality of rest of sites' collapses
	jQuery('.mobile-hall').click(function(){
		var hall = jQuery(this).find('.mobile-hall-info');
		mobile_room_accordion(hall);
	});

	//Run the sizing function whenever we resize to ensure our buttons don't wander off.
	//jQuery(window).resize(spaces_images_sizing);


	//Make click function work on images
	jQuery("a.thumbclick").click(function(e) {
    e.preventDefault();
    $imgURL = jQuery(this).css('background-image');
    $largeSRC = jQuery(".venuelargeimg").css('background-image');

    if($imgURL == $largeSRC){
    	return false;
    }else{
	    jQuery(".venuelargeimg")
        .fadeOut(100, function() {
            jQuery(".venuelargeimg").css('background-image', $imgURL);
        })
        .fadeIn(100);
		}
	});

	//Remove wordpress inline width and height properties
	jQuery('.venuerightcontainer ul li img, .venuelargeimg img').each(function(){
		jQuery(this).removeAttr('width')
		jQuery(this).removeAttr('height');
	});


});

// function spaces_swap_image(target) {
// 	//Get the height/width of the frame, so that we can apply negative
// 	//margins to the child image in order to 'center' it.
// 	var frameHeight = jQuery('.spaces-hero-image').height();
// 	var frameWidth 	= jQuery('.spaces-hero-image').width();
// 	var src = target.attr('src');
// 	jQuery('.spaces-hero-image img').attr('src', src);
// 	//Stick negative margins on the image to center it.
// 	/*jQuery('.spaces-hero-image img').css({
// 		'margin-top': '-' + ((jQuery('.spaces-hero-image img').height() - frameHeight) / 2) + 'px',
// 		'margin-left': '-' + ((jQuery('.spaces-hero-image img').width() - frameWidth) / 2) + 'px',
// 	});*/
// 	jQuery('.spaces-hero-controls div').removeClass('active');
// 	target.parent().addClass('active');
// }

// function spaces_images_sizing() {
// 	// set the height as (height of one persona menu item)+its border,padding * number of persona items - 1 since last one has no bottom border
// 	//jQuery(".gallery-vertical-left").css({'height': + ((jQuery("#sidebar-persona-menu .sidebar-persona-menu-group").height() + 3) * jQuery("#sidebar-persona-menu .sidebar-persona-menu-group").length - 1) + 'px'});
// 	//var totalWidth = jQuery('.spaces-hero-controls').width() - 15;
// 	//var totalHeight = jQuery('.spaces-hero-image.gallery-vertical-left').height() - 80;
// 	//jQuery('.spaces-hero-controls div').width(totalWidth / 2).height(totalHeight / 2);
// 	//var frameWidth = jQuery('.spaces-hero-controls div').width();
// 	/*for( i = 0; i <4; i++) {
// 		jQuery('.spaces-hero-controls div img').eq(i).css({
// 			'margin-left': '-' + ((jQuery('.spaces-hero-controls div img').eq(i).width() - frameWidth) / 2) + 'px',
// 			'margin-top': '-' + ((jQuery('.spaces-hero-controls div img').eq(i).height() - 80) / 2) + 'px'
// 		});
// 	}*/
// }

function mobile_room_accordion(hall) {
	if(jQuery(hall).is(":visible")){
		jQuery('.mobile-hall-info').slideUp();
	}
	else {
		jQuery('.mobile-hall-info').slideUp();
		jQuery(hall).slideDown();
	}
}