(function ($) {
    $(document).ready( function(){
        function media_upload( button_class, media_url, count) {
            var _custom_media = true,
                _orig_send_attachment = wp.media && wp.media.editor.send.attachment;
            $('body').on('click',button_class, function(e) {
                var target = media_url + $(this).attr('data-count');
                var button_id ='#'+$(this).attr('id');
                /* console.log(button_id); */
                var self = $(button_id);
                var send_attachment_bkp = wp.media && wp.media.editor.send.attachment;
                var button = $(button_id);
                var id = button.attr('id').replace('_button', '');
                _custom_media = true;
                wp.media.editor.send.attachment = function(props, attachment){
                    if ( _custom_media  ) {
                        $(target).val(attachment.url);
                    } else {
                        return _orig_send_attachment.apply( button_id, [props, attachment] );
                    }
                };
                wp.media.editor.open(button);
                return false;
            });
        }
        media_upload( '.kc_icon_upload', '.kc_icon_media_url_');
        media_upload( '.kc_icon_active_upload', '.kc_icon_active_media_url_');
    });
})(jQuery);

